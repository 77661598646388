<template>
  <div>
    <Header/> 
    <div class="content_text">
      <!-- <router-link :to="{path:'/'}">
        <img class="content_img_1" src="../../assets/content/logo_s.png">
      </router-link> -->
        <img class="content_img_2_4" :src="logo">
        <div calss="content_study">{{logo_title}}</div>
    </div>
    
    <div class="svsv">
        <div class="topic-item" :class="{training_schedule_one:!port || port == 'phone',training_schedule_two:port == 'pc'}">
            <img :src="sv_img" style="width:100%;height:100%;">
        </div>
        <div v-for="item in lesson_item_list" :key="item" style="width: 100%;height: 100.8px;border-bottom: 1px solid #fff;">
          <a :href="'./training_schedule?type='+item.id">
            <img :src="item.image" style="width:100%;height:100%;">
          </a>
        </div>

        <div v-for="items in courses_list" :key="items">
              <router-link :to="{path:'/training_schedule/'+items.id}">
              <div class="lesson-item">
                  <div class="lesson-item_1">
                      <img :src="items.cover">
                  </div>
                  <div class="lesson-item_8">
                      <p class="pb_1">{{items.title}}</p>
                      <p class="pb_2">{{items.sub_title}}</p>
                      <p class="pb_3">{{items.third_title}}</p>
                  </div>
              </div>
              </router-link>
          </div>
        <div style="width:100%;height:90px;"></div>
    </div>
  </div>
</template>

<style>
.training_schedule_one{
  width: 100%;
  height: 100.8px;
  border-bottom: 1px solid #fff;
}
.training_schedule_two{
  width: 100%;
  height: 20vh;
  border-bottom: 1px solid #fff;
}
.vip-icon img{
  margin-top: 55px;
  width: 20px;
  height: 20px;
}
.vip-icon{
  position: absolute;
  padding: 0;
  right: 5px;
  top: 0;
  bottom: 0;
  /* background-color: rgba(255, 0, 0, 0.3); */
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-filter: drop-shadow(0 0 4px #d4b661);
  filter: drop-shadow(0 0 4px #d4b661);
}
.text_1{
   width: 100%;
   vertical-align: bottom;
   height: 7rem;
   position: relative;
   border-bottom: 1px solid #fff;
 }
p{
  white-space: normal;
}
.lesson-item_8 p{
  margin: 0 0 0 0;
}
.lesson-item_8{
  width: 65%;
  height: auto;
  margin:auto 5px auto 5px;
  word-wrap: break-word;
}
.lesson-item_1{
  width: 125px;
  height: auto;
}
.lesson-item_1 img{
  width: 100%;
  height:100%;
}
.pb_1{
  font-family: Helvetica Neue;
    font-weight: 300;
    font-size: 12px;
    text-align: left;
    color: rgba(0,0,0,.7);
}
.pb_2{
  font-family: Helvetica Neue;
    font-weight: 500;
    font-size: 20px;
    text-align: left;
    color: rgba(0,0,0,.7);
}
.pb_3{
    font-family: Helvetica Neue;
    font-weight: 500;
    font-size: 14px;
    text-align: left;
    color: rgba(0,0,0,.7);
}
.lesson-item{
  height: auto;
  border-bottom: 1px solid #fff;
  display: flex;
  position: relative;
  min-height: 70px;
}
    .topic-item {
        margin: 0;
        border-bottom: 1px solid #fff;
        border-top: 1px solid #fff;
    }
  .svsv{
    height: auto;
    width: 100%;
    float:left;
    overflow: hidden auto;
  }
  .svss{
    height: auto;
    width: 100%;
  }

  .content_study{
    font-family: Helvetica Neue;
    font-weight: 400;
    font-size: 20px;
    text-align: left;
    color: #fff;
  }
  .content_text{
    padding-left: 17px;
    height: 76px;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    font-family: Helvetica Neue;
    font-weight: 400;
    font-size: 20px;
    text-align: left;
    color: #fff;
  }
  .content_img_1{
    margin-right: 13px;
    width: 60px;
    height: 55px;
  }
  .content_img_2_4{
    width: 50px;
    height: 42px;
    margin-right: 8px;
  }
</style>
<script>
import Header from "../Header";
import {apiCourseTypesDetail,apiCreateCourseTypes,apiCourseList} from "@/request/api";
export default {
  components:{Header},
  data(){
    return {
      logo:require('@/assets/content/logo_yi.png'),
      logo_title:'',
      type:0,
      sv_img:'',
      // 課程類型
      lesson_item_list:[],
      // 課程列表
      courses_list:[],
      port:'',
    }
  },
  methods:{
  },
  created(){
    let type = this.$route.query.type;
    let lang_id = this.$store.state.lang_id;
    this.port = this.$store.state.port;
    if(type && type > 0){

      // 明細
      apiCourseTypesDetail(type,{
        id:type,
        lang_id:lang_id
      }).then(res=>{
        if(res.code == 200){
            this.logo_title = res.data.title;
            this.sv_img = res.data.image;
            this.is_need_buy = 1;

            this.$store.state.parent_course_type_id = res.data.parent_id;
        }else{
          this.$message.error(res.message);
          this.$router.push({path:'/'});
          return false;
        }
      }).catch(error=>{
        console.log(error);
      })

      // 查找有沒有子級課程類型
      apiCreateCourseTypes({
        parent_id:type,
        lang_id:lang_id,
      }).then(res=>{
        this.lesson_item_list = res.data.courseTypeList;
      }).catch(error=>{
        console.log(error);
      })


      apiCourseList({
        course_type_id:type,
        lang_id:this.$store.state.lang_id,
      }).then(res=>{
        this.courses_list = res.data;
      }).catch(error=>{
        console.log(error);
      })
    }
  },
}
</script>

